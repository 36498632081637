@use 'sass:math';
@import '~bootstrap/scss/bootstrap';

@font-face {
  font-family: BigShot;
  src: url(./font/big-shot.ttf);
}

@font-face {
  font-family: Cubic;
  src: url(./font/cubic.ttf);
}

// $main-bg-color: #14141f;
// $main-text-color: #ffffff;
$main-bg-color: #ffffff;
$main-text-color: #26002c;
$side-bar-bg-color: #26002c;
$side-bar-text-color: #ffffff;
$border-color: #9353b8;
$bg-color-dark: #26002c;
$sub-bg-color-dark: #ffeafb;
$sub-bg-color-dark2: #d0b1e5;
$sub-bg-color-bright: #b645ff;
$text-color-light: #ffffff;
$btn-color: #9353b8;

html,
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  font-family: BigShot, Cubic !important;
}

html {
  min-height: 100vh;
  display: flex;
}

body {
  width: 100vw;
  flex-grow: 1;
  display: flex;
}

a {
  text-decoration: none !important;
}

pre,
.form-control {
  font-family: BigShot !important;
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  #enter-pg-loading {
    height: 3rem;
    width: 3rem;
  }
}

.bg-main-color {
  background-color: $main-bg-color;
  color: $main-text-color;
}

#main {
  width: 100%;
  flex-grow: 1;
  display: flex;
}

#page-wrap {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
}

.Toastify__toast-theme--light {
  padding: 5px 10px !important;
  font-family: BigShot, Cubic !important;
}

$side-bar-fs: 1.5rem;
$side-bar-fs-active: 1.75rem;
$z-index-sidebar: 9999;
$sidebar-width: 17;
.sidebar {
  @extend .ps-4;
  @extend .pt-3;
  width: percentage(math.div($sidebar-width, 100));
  background-color: $side-bar-bg-color;
  color: $side-bar-text-color;
  a {
    text-decoration: none;
  }
  .side-bar-title {
    font-size: $side-bar-fs;
    z-index: $z-index-sidebar;
    color: $side-bar-text-color;
  }
  .side-bar-title:hover {
    font-size: $side-bar-fs-active;
    text-decoration: underline;
    cursor: pointer;
  }
  .disable {
    font-size: $side-bar-fs;
    color: gray;
  }

  .active {
    font-size: $side-bar-fs-active;
    font-weight: bold;
    text-decoration: underline;
  }

  .status {
    font-size: 1rem;
    .status-title {
      font-size: 1.25rem;
      text-decoration: underline;
      text-align: center;
      margin-bottom: 1rem;
    }
    .total-layer {
    }
    .show-uploaded-pic {
    }
    .total-combinations {
    }
    .generated-num {
    }
    .selected-num {
    }

    .merging-status {
      p {
        display: inline;
      }
    }
  }
}

$vr-width: 1;
#vr {
  width: percentage(math.div($vr-width, 100));
}

$main-title-fs: 2rem;
$sub-title-fs: 1.5rem;
$sub-sub-title-fs: 1rem;
$padding-l: 1.5rem;
$padding-t: 2rem;
$padding-r: 1.5rem;
.pg-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 10;
  width: percentage(math.div((100-$sidebar-width - $vr-width), 100));
  .upload-pg {
    width: 60%;
    #noti-wrap {
      #noti-container {
        margin-top: 1.5rem;
        margin-right: 1.5rem;
      }
    }
    .header {
      margin-bottom: 2rem;
      text-align: center;
    }

    .example {
      display: flex;
    }

    .example-2 {
      margin-left: 4rem;
    }
  }
  .layer-pg {
    height: 100%;
    width: 100%;
    padding-left: $padding-l;
    padding-top: $padding-t;
    padding-right: $padding-r;

    .header-wrap {
      display: flex;
      flex-direction: row;
      .title-wrap {
        display: flex;
        flex: row;
        margin-bottom: 1.5rem;
        .title {
          font-size: $main-title-fs;
          margin-right: 1rem;
        }
        #add-layer-btn-wrap {
          #add-layer-btn {
            color: $main-text-color;
            border: 0px;
            border-radius: 0;
            background-color: $sub-bg-color-dark;
            font-size: 1.25rem;
            margin-right: 1rem;
          }
          #add-layer-btn:hover {
            box-shadow: $sub-bg-color-dark2 10px -10px 0px -3px;
          }
        }
      }
      .right-side-header {
        margin-left: auto;
        align-items: center;
        display: flex;

        #rev-order-btn,
        #lexi-order-btn,
        #remove-number-btn {
          color: $main-text-color;
          border: 0px;
          border-radius: 0;
          background-color: $sub-bg-color-dark;
          font-size: 1.25rem;
          margin-right: 1rem;
        }
        #lexi-order-btn:hover,
        #rev-order-btn:hover,
        #remove-number-btn:hover {
          box-shadow: $sub-bg-color-dark2 10px -10px 0px -3px;
        }
      }
    }

    $sample-img-border-color: $border-color;
    .samples {
      padding-bottom: 30px;
      .title {
        font-size: $sub-title-fs;
        margin-bottom: 1rem;
      }
      .sample-section {
      }

      .sample-img-wrap {
        position: relative;
        display: inline-block;
        height: 200px;
        width: 200px;
        border: 2px solid $sample-img-border-color;
        border-radius: 30px;
        margin-right: 30px;
      }
      .sample-img {
        position: absolute;
        top: 0;
        left: 0;
        height: 200px;
        width: 200px;
      }
    }

    $layer-img-border-color: $border-color;
    $title-inp-border-color: $border-color;
    .layer-img-wrap {
    }
    .layer-list {
      .layer {
        margin-bottom: 2rem;
        .layer-header {
          display: flex;
          flex-direction: row;
          .layer-title-slider {
            align-items: center;
            display: inline-block;
            width: auto;
            margin-bottom: 1rem;
            box-shadow: 3px 3px 2px 1px rgba(0, 0, 255, 0.2);
            margin-right: 2rem;
            .title-inp-wrap {
              display: flex;
              flex-direction: row;
              padding-left: 0.5rem;
              overflow: hidden;
              color: $main-text-color;
              .title {
                font-size: $sub-title-fs;
                margin-bottom: 0;
              }
              .title-inp-container {
                background-color: $sub-bg-color-dark;
                :hover {
                  border: 0px;
                }
                position: relative;
                width: auto;
                .edit-icon {
                  top: 25%;
                  right: 0.5rem;
                  position: absolute;
                }
                .title-inp {
                  margin-left: 1rem;
                  font-size: $sub-title-fs;
                  border: none;
                  background-image: none;
                  background-color: $sub-bg-color-dark;
                  -webkit-box-shadow: none;
                  -moz-box-shadow: none;
                  box-shadow: none;
                  padding-left: 0.5rem;
                  width: 85%;
                }
                .title-inp:hover {
                  border: 0.5px solid $title-inp-border-color;
                }
                .title-inp-not-save {
                  border: 0.5px solid #ff0000;
                }
              }
            }
            .slider-del-wrap {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding-left: 0.75rem;
              padding-right: 0.75rem;
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
              background-color: $sub-bg-color-dark2;
              .layer-rarity-slider-wrap {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-grow: 1;
                .layer-rarity-slider {
                  flex-grow: 1;
                }
                .layer-rarity-slider-label {
                }
              }
              .del-layer-btn {
                font-size: 1rem;
                margin-left: 1rem;
                color: #ff0000;
              }
              .del-layer-btn:hover {
                font-size: 1.25rem;
                cursor: pointer;
              }
            }
          }
          .layer-mv-btn {
            display: flex;
            flex-direction: column;
            button {
              border: 0;
              border-radius: 0;
              color: $main-text-color;
            }
            .btn-mv-up {
              margin-bottom: 1rem;
              background-color: $sub-bg-color-dark;
            }
            .btn-mv-up:hover {
              box-shadow: $sub-bg-color-dark2 10px -10px 0px -3px;
            }
            .btn-mv-down {
              background-color: $sub-bg-color-dark2;
            }
            .btn-mv-down:hover {
              box-shadow: $sub-bg-color-dark 10px -10px 0px -3px;
            }
          }
        }
        .layer-info {
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;
          #total-image-div {
            margin-bottom: 0.5rem;
          }

          #show-attr-div {
            display: flex;
          }

          .form-switch {
            margin-left: 0.5rem;
          }

          .form-check-input:checked {
            background-color: #d0b1e5;
            border-color: #d0b1e5;
          }
        }
        .warning {
          .warning-text {
            color: #ff0000;
          }
        }
        .img-row {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          overflow-x: scroll;
          height: auto;
          box-shadow: 7px 7px 2px 1px rgba(0, 0, 255, 0.2);
          padding-top: 20px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 30px;
          background-color: $sub-bg-color-dark;
          color: $main-text-color;
          .img-item {
            margin-right: 30px;
            position: relative;
            .del-icon {
              position: absolute;
              right: 20px;
              top: 0;
              font-size: 1.5rem;
              color: #ff0000;
            }
            .del-icon:hover {
              font-size: 2rem;
              cursor: pointer;
            }
            .img {
              background-color: #ffffff;
              box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
              height: 200px;
              width: 200px;
              margin-bottom: 10px;
            }
            .img-des {
              .layer-img-title-wrap {
                position: relative;
                .layer-img-title-inp {
                  text-align: center;
                  border: none;
                  background-image: none;
                  background-color: transparent;
                  -webkit-box-shadow: none;
                  -moz-box-shadow: none;
                  box-shadow: none;
                  width: 100%;
                }

                .layer-img-title-inp:hover {
                  border: 0.5px solid $title-inp-border-color;
                }

                .inp-not-save {
                  border: 0.5px solid #ff0000;
                }

                .edit-icon {
                  top: 20%;
                  right: 0;
                  position: absolute;
                }
              }
              .img-rarity-slider-wrap {
                display: flex;
                justify-content: space-evenly;
                .img-rarity-slider {
                }
              }
            }
          }
        }
      }
    }
  }

  .preview-pg {
    height: 100%;
    width: 100%;
    padding-left: $padding-l;
    padding-top: $padding-t;
    padding-right: $padding-r;

    .title {
      font-size: $main-title-fs;
    }

    #preview-header {
      display: flex;
      flex-direction: row;
      margin-bottom: 1.5rem;
      #dl-sample {
        margin-left: auto;
      }

      #dl-collection {
        margin-left: 2rem;
      }
    }

    .sort-btn {
      display: flex;
      flex-direction: row;
      margin-bottom: 2rem;
      align-items: center;
      #sort-by {
        @extend .align-middle;
        display: inline;
        width: 100px;
        margin-bottom: 0;
      }

      #select-sort {
        width: auto;
        background-color: $main-bg-color;
        color: $main-text-color;
      }
    }

    $preview-img-border-color: $border-color;
    $preview-img-dim: 200px;
    #preview-contents {
      .preview-item {
        // display: inline-block;
        margin-bottom: 1.5rem;
        margin-right: 1.5rem;
        margin-left: 0;
        margin-top: 0;
        font-family: inherit; /* 1 */
        font-size: 100%; /* 1 */
        line-height: 1.15; /* 1 */
        padding: 0;
        background-color: transparent;
        border: 0;
        color: $main-text-color;

        .preview-img-border {
          position: relative;
          height: $preview-img-dim;
          width: $preview-img-dim;
          border: 2px solid $preview-img-border-color;
          border-radius: 30px;
          margin-bottom: 0.5rem;
          overflow: hidden;
        }
        .preview-img-border:hover::after {
          // content: '';
          // position: absolute;
          // top: 0;
          // left: 0;
          // right: 0;
          // bottom: 0;
          // background: rgba(255, 255, 255, 0.25);
          content: ''; // ::before and ::after both require content
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(120deg, #7700fe, #f6ecff);
          opacity: 0.5;
        }
        .preview-img {
          position: absolute;
          top: 0;
          left: 0;
          height: $preview-img-dim;
          width: $preview-img-dim;
        }

        .preview-text-rarity {
          text-align: center;
        }
      }
    }
  }
  .rules-pg {
    width: 100%;
    height: 100%;
    padding-left: $padding-l;
    padding-top: $padding-t;
    padding-right: $padding-r;
    #title-wrap {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: row;
      #title {
        font-size: $main-title-fs;
      }
      #save-rule {
        margin-left: auto;
        background-color: $sub-bg-color-dark;
        color: $main-text-color;
        border: 0;
      }
      #save-rule:hover {
        box-shadow: $sub-bg-color-dark2 10px -10px 0px -3px;
      }
    }
    #current-rule {
      margin-bottom: 2rem;
      padding-right: 1rem;
      padding-left: 1rem;
      height: 15rem;
      .pic-list-box {
        height: 100%;
        padding: 2rem;
        box-shadow: $sub-bg-color-dark2 8px 8px;
        background-color: $sub-bg-color-dark;
        border-radius: 0.75rem;
        overflow-y: auto;
        .scroll-area {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          overflow-y: auto;
          .pic-name {
            font-size: 0.75rem;
            padding: 0.5rem;
            margin: 0.5rem;
            background-color: $sub-bg-color-dark2;
            border-radius: 0.75rem;
          }
          .pic-name:hover {
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
      .pic-list-box-warning {
        border: 1px solid red;
      }
      #relation-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        .relation-text-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          .relation-emoji {
            font-size: 3rem;
          }
          .relation-text {
            font-size: 1rem;
          }
        }
      }
    }
    #rules-form {
      margin-bottom: 1rem;
      .drop-down-left-right {
        #drop-down-toggle {
          width: 100%;
          border: 0;
          background-color: $sub-bg-color-dark;
          color: $main-text-color;
          border-radius: 0.75rem;
        }
        #drop-down-menu {
          width: 100%;
          .drop-down-item {
            width: 100%;
            display: flex;
            flex-direction: row;
            .pic-name {
            }
            .check-icon {
              margin-left: auto;
            }
          }
        }
      }
      #relation-drop-down {
        .drop-down-toggle {
          width: 100%;
          background-color: $sub-bg-color-dark;
          color: $main-text-color;
          border: 0;
        }
        .drop-down-toggle-warning {
          border: 1px solid red;
        }
        #drop-down-menu {
          .drop-down-item {
          }
        }
      }
    }
    #rules-list {
      padding-right: 1rem;
      padding-left: 1rem;
      #rules-list-title {
        font-size: 1.25rem;
      }
      .rule-wrap {
        .rule-row-wrap {
          .rule-header-wrap {
            display: flex;
            flex-direction: row;
            margin-bottom: 0.5rem;
            .rule-title {
            }
            .rule-header-right {
              margin-left: auto;
              display: flex;
              flex-direction: row;
              .del-rule-btn {
                margin-right: 1rem;
              }
              .rule-active-checkbox {
              }
            }
          }
          background-color: $sub-bg-color-dark;
          border-radius: 1rem;
          padding: 1rem;
          margin-bottom: 1rem;
          .rule-groupLR {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            .pic-name {
              font-size: 0.75rem;
              padding: 0.5rem;
              margin: 0.5rem;
              border-radius: 0.5rem;
              background-color: $sub-bg-color-dark2;
              color: $main-text-color;
            }
          }
          .rule-relation {
            text-align: center;
          }
        }
      }
    }
    #noti-container {
      margin-top: 1.5rem;
      margin-right: 1.5rem;
    }
  }
  .custom-pg {
    height: 100%;
    width: 100%;
    padding-left: $padding-l;
    padding-top: $padding-t;
    padding-right: $padding-r;
    display: flex;
    flex-direction: column;
    #custom-pg-title {
      font-size: $main-title-fs;
    }
    #gen-header {
      margin-bottom: 1rem;
      #generate-inp-form {
        #generate-inp-label {
          color: $main-text-color;
        }
        #generate-inp {
          border: 2px solid $border-color;
          background-color: $main-bg-color;
          color: $main-text-color;
        }
        #generate-inp-warning {
          color: #ff0000;
        }
      }

      #select-seed-by {
        margin-bottom: 1rem;
        margin-right: 1rem;
        border: 1px solid $sub-bg-color-dark2;
        width: 180px;
      }

      #gen-header-seed,
      #gen-header-setting,
      #seed-setting {
        flex-wrap: wrap;
        display: flex;
        align-items: flex-start;
        margin-top: 0.5rem;
      }

      #seed-setting {
        justify-content: flex-end;
      }

      #ran-gen-label {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.5rem;
        #gen-num {
          align-self: center;
          flex-grow: 3;
          font-weight: bold;
          font-size: 1.2rem;
        }
      }

      $btn-width: 200px;

      #ran-gen-btn {
        border-radius: 0;
        border: 0;
        color: $main-text-color;
        background-color: $sub-bg-color-dark;
        margin-right: 1rem;
        width: $btn-width;
        margin-bottom: 1rem;
      }
      #ran-gen-btn:hover {
        box-shadow: $sub-bg-color-dark2 10px -10px 0px -3px;
      }

      #add-global-extra-btn,
      #save-seed-btn {
        border-radius: 0;
        border: 0;
        color: $main-text-color;
        background-color: $sub-bg-color-dark;
        margin-right: 1rem;
        margin-bottom: 1rem;
        width: $btn-width;
      }

      #add-global-extra-btn:hover,
      #save-seed-btn:hover {
        box-shadow: $sub-bg-color-dark2 10px -10px 0px -3px;
      }
      #del-seed-btn {
        border-radius: 0;
        border: 0;
        width: $btn-width;
        margin-right: 1rem;
        margin-bottom: 1rem;
      }
    }

    #show-no-layer-upload-wrap {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      #show-no-layer-upload {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
    #no-pic-generate-wrap {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      #no-pic-generate {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
    #display-box {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      #display-box-header {
        display: flex;
        flex-direction: row;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        align-items: center;
        #select-display-by-wrap {
          display: flex;
          flex-direction: row;
          align-items: center;
          #display-by {
          }

          #select-display-by {
            width: auto;
            background-color: $main-bg-color;
            color: $main-text-color;
            margin-left: 1.5rem;
          }
        }
        #sub-header-right-side {
          margin-left: auto;
          display: flex;
          flex-direction: row;
          #select-all-btn {
            background-color: $sub-bg-color-dark;
            border-radius: 0;
            border: 0;
            color: $main-text-color;
            margin-right: 1rem;
          }
          #select-all-btn:hover {
            box-shadow: $sub-bg-color-dark2 10px -10px 0px -3px;
          }
          #deselect-all-btn {
            background-color: $sub-bg-color-dark;
            border-radius: 0;
            border: 0;
            color: $main-text-color;
          }
          #deselect-all-btn:hover {
            box-shadow: $sub-bg-color-dark2 10px -10px 0px -3px;
          }
        }

        #clear-all {
          margin-left: auto;
        }

        #select-all {
          margin-left: 1rem;
        }
      }
      #display-box-body {
        width: 100%;
        display: flex;

        $pic-item-dimension: 200px;
        $show-all-img-border: $border-color;
        #show-all-box {
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          .select-panel-wrap {
            margin-bottom: 1rem;
            .select-panel-header {
              display: flex;
              flex-direction: row;
              .select-panel-header-title {
                margin-right: 0.5rem;
              }
              .arrow-dn-select-panel {
                box-sizing: border-box;
                position: relative;
                top: 3px;
                height: 12px;
                width: 12px;
                border-style: solid;
                border-color: $main-text-color;
                border-width: 0px 1px 1px 0px;
                transform: rotate(45deg);
                transition: border-width 150ms ease-in-out;
              }
            }
            .select-panel-header:hover {
              cursor: pointer;
              .select-panel-header-title {
                text-decoration: underline;
              }
              .arrow-dn-select-panel {
                border-bottom-width: 3px;
                border-right-width: 3px;
              }
            }
            #select-panel {
              .layer-row {
                .label-layername-wrap {
                  display: inline-flex;
                  flex-direction: row;
                  .label-layername {
                    margin-right: 0.5rem;
                    margin-left: 0.5rem;
                  }
                  .arrow-down {
                    box-sizing: border-box;
                    position: relative;
                    top: 5px;
                    height: 10px;
                    width: 10px;
                    border-style: solid;
                    border-color: $main-text-color;
                    border-width: 0px 1px 1px 0px;
                    transform: rotate(45deg);
                    transition: border-width 150ms ease-in-out;
                    margin-right: 0.5rem;
                  }
                }
                .label-layername-wrap:hover {
                  cursor: pointer;
                  .label-layername {
                    text-decoration: underline;
                  }
                  .arrow-down {
                    border-bottom-width: 4px;
                    border-right-width: 4px;
                  }
                }
                .checkbox-wrap {
                  .checkbox {
                  }
                }
              }
            }
          }
          .inf-scroll {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .pic-item {
              margin-bottom: 1rem;
              margin-left: 1rem;
              margin-top: 1rem;
              min-height: $pic-item-dimension;
              min-width: $pic-item-dimension;
              align-items: center;
              display: flex;
              justify-content: center;
              position: relative;
              .pic-item-spinner {
              }
              .pic-item-wrap {
                display: flex;
                flex-direction: column;
                .pic-item-id {
                  display: flex;
                  align-items: center;
                  flex-wrap: nowrap;
                  width: 200px;
                  background-color: $sub-bg-color-dark;
                  color: $main-text-color;
                  padding: 0.3em 0.5em;
                  border-radius: 0.5em 0.5em 0 0;
                  overflow: hidden;
                  height: 36px;
                }
                .pic-item-id-text {
                  width: 50%;
                }
                .pic-item-id-input {
                  margin-right: 5px;
                  width: 38%;
                  background-color: $sub-bg-color-dark;
                  color: $main-text-color;
                  border: 0;
                }
                .pic-item-id-input:hover {
                  border: 0.5px solid $border-color;
                }

                .edit-icon {
                  cursor: pointer;
                }

                .pic-item-border {
                  position: relative;
                  .pic-item-img {
                    height: $pic-item-dimension;
                    width: $pic-item-dimension;
                  }
                  .select-emoji {
                    position: absolute;
                    font-size: 30px;
                    bottom: 0;
                    right: 5%;
                  }
                }
                .overlay {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: $pic-item-dimension;
                  width: $pic-item-dimension;
                  opacity: 0;
                  transition: 0.5s ease;
                  cursor: pointer;
                  .select-text {
                    font-size: 20px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    text-align: center;
                    font-weight: bold;
                    text-shadow: 2px -2px $main-text-color;
                    color: $sub-bg-color-dark;
                  }
                }
                .overlay:hover {
                  opacity: 1;
                  box-shadow: $sub-bg-color-dark2 12px -12px 0px -3px;
                }

                .btn-wrap {
                  padding-top: 0.5rem;
                  display: flex;
                  flex-direction: row;
                  .repick-btn {
                    flex-grow: 1;
                    background-color: $sub-bg-color-dark;
                    color: $main-text-color;
                    border: 0;
                    border-radius: 0;
                    margin-right: 0.5rem;
                  }
                  .repick-btn:hover {
                    box-shadow: $sub-bg-color-dark2 10px -10px 0px -3px;
                  }
                  .add-attr-btn {
                    flex-grow: 1;
                    background-color: $sub-bg-color-dark;
                    color: $main-text-color;
                    border: 0;
                    border-radius: 0;
                  }
                  .add-attr-btn:hover {
                    box-shadow: $sub-bg-color-dark2 10px -10px 0px -3px;
                  }
                }
              }
            }
          }

          .load-more-btn {
            padding: 0.4em;
            margin-top: 2rem;
            align-self: center;
            border-radius: 0;
            border: 0;
            color: $main-text-color;
            background-color: $sub-bg-color-dark;
            margin-right: 1rem;
            margin-bottom: 1rem;
            width: 180px;
          }
        }
        #pool-box {
        }

        $pics-table-img-dimension: 200px;
        $pics-table-img-dimension-hover: 225px;
        #table-box {
          width: 100%;
          #sort-by-layer-wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            #sort-by-layer-label {
            }

            #select-sort-by-layer {
              margin-left: 1.5rem;
              width: auto;
              background-color: $main-bg-color;
              color: $main-text-color;
              .layername-option {
              }
            }
          }
          #not-selected-pics-table {
            background-color: $main-bg-color;
            color: $main-text-color;
          }

          #table-wrap {
            margin-top: 1.5rem;
            overflow: hidden;
            table {
              // background-color: $main-bg-color;
              // color: $main-text-color;
              width: 100%;
              border-collapse: collapse;
              table-layout: fixed;
              border: 2px solid $border-color;
              colgroup {
                .pic-name-col {
                  width: 10%;
                  //td
                }
              }
              thead {
              }
              tbody {
                tr {
                  .row-label {
                    word-wrap: break-word;
                    white-space: pre-wrap;
                    word-break: break-word;
                    .pic-name-label {
                    }
                    .pic-prob-label {
                    }
                    .pic-num-label {
                    }
                  }
                  .pics-row-td {
                    //td
                    .pics-row-div {
                      //div
                      display: flex;
                      flex-wrap: nowrap;
                      overflow-x: auto;
                      background-color: transparent;
                      border: 0px;
                      $btn-size: 50px;
                      $btn-size-hover: 60px;
                      .back-btn {
                        font-size: $btn-size;
                      }
                      .back-btn:hover {
                        font-size: $btn-size-hover;
                        cursor: pointer;
                      }
                      .pics-table-img-wrap {
                        margin-bottom: 0.5rem;
                        margin-left: 1rem;
                        margin-top: 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .pics-table-img-border {
                          position: relative;
                          height: $pics-table-img-dimension;
                          width: $pics-table-img-dimension;
                          margin-bottom: 0.5rem;
                          align-items: center;
                          display: flex;
                          justify-content: center;
                          .pic-wait-spinner {
                            width: 3rem;
                            height: 3rem;
                          }
                          .pics-table-img {
                            height: $pics-table-img-dimension;
                            width: $pics-table-img-dimension;
                            border: 2px solid $border-color;
                            border-radius: 30px;
                            cursor: pointer;
                          }
                          .pics-table-img:hover {
                            height: $pics-table-img-dimension-hover;
                            width: $pics-table-img-dimension-hover;
                          }

                          .pics-table-img::after {
                            content: '';
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            background-color: #ffffff;
                            opacity: 0.5;
                          }
                          // .selected::after {
                          //   content: ''; // ::before and ::after both require content
                          //   position: absolute;
                          //   top: 0;
                          //   left: 0;
                          //   width: 100%;
                          //   height: 100%;
                          //   background-image: linear-gradient(
                          //     120deg,
                          //     #7700fe,
                          //     #f6ecff
                          //   );
                          //   opacity: 0.5;
                          // }
                        }

                        .img-des {
                          .prob {
                            text-align: center;
                          }
                          .img-des-ul {
                            .img-des-li {
                              list-style: none;
                            }

                            .img-des-li::before {
                              content: '📑';
                              margin-right: 0.25rem;
                              margin-left: -1rem;
                            }
                          }
                        }
                      }
                      .next-btn {
                        font-size: $btn-size;
                      }
                      .next-btn:hover {
                        font-size: $btn-size-hover;
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        #search-box {
        }
      }
    }

    #noti-wrap {
      #noti-container {
        margin-top: 1.5rem;
        margin-right: 1.5rem;
      }
    }
  }

  $img-dimension: 200px;
  $deploy-pg-img-border-color: #ffffff;
  .deploy-pg {
    width: 100%;
    height: 100%;
    padding-left: $padding-l;
    padding-top: $padding-t;
    padding-right: $padding-r;
    #deploy-header {
      margin-bottom: 1rem;
      #title {
        font-size: $main-title-fs;
      }
    }
    #selected-img-box-wrap {
      #selected-img-header {
        margin-bottom: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        #selected-img-header-title {
          font-size: $sub-title-fs;
        }
        #selected-img-header-sub-title {
          margin-left: 0.5rem;
          font-size: $sub-sub-title-fs;
        }
      }
      #selected-img-box {
        border: 2px solid $deploy-pg-img-border-color;
        border-radius: 30px;
        padding: 1rem;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        .selected-img {
          width: $img-dimension;
          height: $img-dimension;
          border: 2px solid $deploy-pg-img-border-color;
          border-radius: 30px;
          margin-right: 1.5rem;
        }
      }
    }

    #stats {
      margin-top: 1rem;
      #stats-title {
        font-size: $main-title-fs;
      }
      #stats-table {
        color: $main-text-color;
      }
    }

    #collection-picture {
      padding-left: 2rem;
      padding-right: 2rem;

      #collection-pic-form {
        #form-label {
          position: relative;
          text-align: center;
          width: 100%;
          $pic-size: 320px;
          #collection-pic-holder {
            width: $pic-size;
            height: $pic-size;
            cursor: pointer;
            box-shadow: $sub-bg-color-dark2 10px -10px 0px -3px;
            border: 1px solid gray;
          }
          #overlay {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: $pic-size;
            height: $pic-size;
            opacity: 0;
            transition: 0.5s ease;
            cursor: pointer;
            #upload-text {
              font-size: 2.5rem;
              font-weight: bold;
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              text-align: center;
              font-weight: bold;
              text-shadow: 2px -2px $main-text-color;
              color: $sub-bg-color-dark;
            }
          }
          #overlay:hover {
            opacity: 1;
          }
        }
        #collection-pic-inp {
          display: none;
        }
      }
      #choose-from-collection-wrap {
        display: flex;
        justify-content: center;
        #choose-from-collection-btn {
          border-radius: 0;
        }
      }
    }

    #meta-data-form {
      margin-bottom: 1rem;
      #collection-name-wrap {
        margin-bottom: 1rem;
        #collection-name-label {
        }
        #collection-name-inp {
        }
        #collection-name-help-text {
        }
      }
      #desc-wrap {
        margin-bottom: 1rem;
        #desc-label {
          a {
            margin-left: 0.5rem;
          }
        }
        #desc-inp {
        }
        #desc-help-text {
        }
      }
      #img-dim-wrap {
        margin-bottom: 1rem;
        #img-dim-label {
        }
        #img-dim {
        }
      }
      #seller-fee-wrap {
        margin-bottom: 1rem;
        #seller-fee-label {
        }
        #seller-fee-dim {
        }
      }
      #ext-link-wrap {
        margin-bottom: 1rem;
        #ext-link-label {
        }
        #ext-link-dim {
        }
      }
      #fee-recipient-wrap {
        margin-bottom: 1rem;
        #fee-recipient-label {
        }
        #fee-recipient-dim {
        }
      }

      #meta-data-form-btn {
      }
    }

    #noti-wrap {
      #noti-container {
        margin-top: 1.5rem;
        margin-right: 1.5rem;
      }
    }
  }

  .merging-pg {
    width: 100%;
    height: 100%;
    padding-left: $padding-l;
    padding-top: $padding-t;
    padding-right: $padding-r;
    display: flex;
    justify-content: center;
    align-items: center;
    #progress-bar-wrap {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
      #progress-bar-text-wrap {
        margin-bottom: 1rem;
        #icon {
          height: 2rem;
          width: 2rem;
          margin-right: 0.5rem;
        }
        #progress-bar-text {
        }
      }
      #progress-bar {
        width: 100%;
        .bg-AS_SUB_COLOR2 {
          background-color: $sub-bg-color-dark2;
        }
      }
    }
  }
  .uploading-pg {
    width: 100%;
    height: 100%;
    padding-left: $padding-l;
    padding-top: $padding-t;
    padding-right: $padding-r;
    display: flex;
    justify-content: center;
    align-items: center;
    #progress-bar-wrap {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
      #progress-bar-text-wrap {
        margin-bottom: 1rem;
        #icon {
          height: 2rem;
          width: 2rem;
          margin-right: 0.5rem;
        }
        #progress-bar-text {
        }
      }
      #progress-bar {
        width: 100%;
        .bg-AS_SUB_COLOR2 {
          background-color: $sub-bg-color-dark2;
        }
      }
    }
  }
  .uploading-meta-pg {
  }

  #api-invalid-pg {
    #fail-text {
      color: red;
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 1rem;

      #retry-btn {
      }
    }
    #option {
      #show-social-icons {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        font-size: 1.5rem;
        .icon {
          margin-right: 2rem;
        }
      }
    }
    #noti-container {
      padding-right: 1rem;
      padding-top: 1rem;
    }
  }

  #submit-fail-pg {
    #fail-text {
      color: red;
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    #option {
      display: flex;
      flex-direction: row;
      #retry-btn {
        flex-grow: 1;
        margin-right: 1rem;
      }
      #go-back-btn {
        flex-grow: 1;
      }
    }
  }
}

#uploading-modal {
  #uploading-modal-header {
  }
  #uploading-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    #uploading-modal-body-text {
      font-size: 1rem;
      margin-right: 1rem;
    }
    #uploading-spinner {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
}

#choose-collection-pic-modal {
  #modal-body {
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
    .inf-scroll {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      $img-size: 200px;
      .pic-item {
        margin-right: 1rem;
        margin-bottom: 1rem;
        height: $img-size;
        width: $img-size;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid gray;
        .pic-item-wrap {
          .pic-item-border {
            .pic-item-img {
              height: 100%;
              width: 100%;
            }
            .pic-item-img:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

#extra-attr-modal {
  #extra-attr-modal-header {
  }
  #extra-attr-modal-body {
    max-height: 70vh;
    overflow-y: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    #body-header {
      margin-bottom: 1rem;
      display: flex;
      flex: row;
      #add-new-attr-btn {
        padding: 0.4rem 2rem;
        margin-left: auto;
        background-color: $sub-bg-color-dark;
        color: $main-text-color;
        border: 0;
        border-radius: 0;
        margin-bottom: 1rem;
      }
      #add-new-attr-btn:hover {
        box-shadow: $sub-bg-color-dark2 10px -10px 0px -3px;
      }
    }
    #attr-form {
      #attr-form-header {
        margin-bottom: 1rem;
        #index {
          text-align: center;
        }
        #key {
          text-align: center;
        }
        #equal {
          text-align: center;
        }
        #value {
          text-align: center;
        }
        #action {
          text-align: center;
        }
      }
      .attr-wrap {
        margin-bottom: 0.6rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        .index {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;
          text-align: center;

          .badge {
            margin-left: 0.5em;
            background-color: #26002c !important;
          }
        }
        .key {
          font-family: Arial, Helvetica, sans-serif;
        }
        .equal {
          text-align: center;
          margin: auto;
        }
        .value {
          font-family: Arial, Helvetica, sans-serif;
        }
        .action {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .row-bg {
        background-color: $sub-bg-color-dark;
        .key {
          background-color: $sub-bg-color-dark;
        }
        .value {
          background-color: $sub-bg-color-dark;
        }
      }
    }
  }
  #extra-attr-modal-footer {
    #warn-not-save {
      color: red;
    }
    #warn-save-err {
      color: red;
    }
    #save-attr-btn {
      background-color: $sub-bg-color-dark;
      color: $main-text-color;
      border: 0;
      border-radius: 0;
    }
    #save-attr-btn:hover {
      box-shadow: $sub-bg-color-dark2 10px -10px 0px -3px;
    }
  }
}

#random-gen-modal {
  .modal-title {
    width: 100%;
    text-align: center;
  }

  #random-gen-modal-body {
    display: flex;
    justify-content: center;
    line-height: 32px;
  }

  #random-gen-modal-body-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #random-gen-spinner {
    margin-top: 0.5em;
    margin-left: 0.5em;
    width: 24px;
    height: 24px;
  }
}

// upload stage track progress
.bs4-order-tracking {
  margin-bottom: 30px;
  overflow: hidden;
  padding-left: 0px;
  margin-top: 30px;
  color: #878788;
}
.bs4-order-tracking li {
  list-style-type: none;
  font-size: 13px;
  width: 33%;
  float: left;
  position: relative;
  font-weight: 400;
  color: #878788;
  text-align: center;
}
.bs4-order-tracking li:first-child:before {
  margin-left: 15px !important;
  padding-left: 11px !important;
  text-align: left !important;
}
.bs4-order-tracking li:last-child:before {
  margin-right: 5px !important;
  padding-right: 11px !important;
  text-align: right !important;
}
.bs4-order-tracking li > div {
  color: #fff;
  width: 29px;
  text-align: center;
  line-height: 29px;
  display: block;
  font-size: 12px;
  background: #878788;
  border-radius: 50%;
  margin: auto;
}
.bs4-order-tracking li:after {
  content: '';
  width: 150%;
  height: 2px;
  background: #878788;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 15px;
  z-index: -1;
}
.bs4-order-tracking li:first-child:after {
  left: 50%;
}
.bs4-order-tracking li:last-child:after {
  left: 0% !important;
  width: 0% !important;
}
.bs4-order-tracking li.active {
  font-weight: bold;
  color: $sub-bg-color-bright;
}
.bs4-order-tracking li.active > div {
  background: $sub-bg-color-bright;
}
.bs4-order-tracking li.active:after {
  background: $sub-bg-color-bright;
}
.card-timeline {
  z-index: 0;
}
.card {
  border: 0;
  margin-bottom: 1rem;
}
